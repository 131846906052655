import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
//import { commonAcqFieldsMap } from "../../../../core/dict/acqFieldsMap"
import { governingBodiesPosition } from "../../../../core/dict/merchantsAppTabs"
import { validateForm } from "../helpers/valid";

export const guideDataFormNamesMap = {
	director: "director",
	contactPerson: "contactPerson",
	pretensionPerson: "pretensionPerson",
	accountant: "accountant",
	signer: "signer",
	foundationIndividuals: "foundationIndividuals",
	foundationLegals: "foundationLegals",
	beneficiaryIndividuals: "beneficiaryIndividuals",
	governingBodies: "governingBodies",
};

export const setDataBySections = ({ 
	bankParams, 
	dispatch, 
	forms, 
	mergeData = false, 
	setErrorFields,
	setErrorTabs,
}) => {

	const setListDataBySection = (data, sectionType, form) => {
		form.setFieldsValue({[sectionType]: data})
		if(mergeData){
			dispatch(setGuideDataSectionByType({[sectionType]: form.getFieldsValue()[sectionType], sectionType: sectionType}))
			validateForm(form, setErrorFields, setErrorTabs)
		} else {
			dispatch(setGuideDataSectionByType({[sectionType]: data, sectionType: sectionType}))
		}
	};

	const setDataBySection = (data, sectionType, form) => {
		form.setFieldsValue(data)
		if(mergeData){
			dispatch(setGuideDataSectionByType({...form.getFieldsValue(), sectionType: sectionType}))
			validateForm(form, setErrorFields, setErrorTabs)
		} else {
			dispatch(setGuideDataSectionByType({...data, sectionType: sectionType}))

		}
	};

	for (let index = 0; index < Object.keys(guideDataFormNamesMap).length; index++) {
		const section = Object.keys(guideDataFormNamesMap)[index]

		if (
			[
				guideDataFormNamesMap.foundationLegals, 
				guideDataFormNamesMap.foundationIndividuals, 
				guideDataFormNamesMap.beneficiaryIndividuals,
				guideDataFormNamesMap.governingBodies,
			].includes(section)
		) {
			if(bankParams.hasOwnProperty(section)) setListDataBySection(bankParams[section], section, forms[section])
			continue;
		};

		if(section === 'contactPerson' && bankParams.hasOwnProperty(section)) {
			setDataBySection(bankParams[section], section, forms[section])
			if(bankParams?.contactPerson?.hasOwnProperty('pretensionPerson')){
				setDataBySection(bankParams?.contactPerson?.pretensionPerson, 'pretensionPerson', forms['pretensionPerson'])
			}
			continue;
		};

		if(bankParams.hasOwnProperty(section)) setDataBySection(bankParams[section], section, forms[section])
	}
};

const initialState = {
	loading: {
		isDataLoad: false, 
		isDataValidate: false, 
	},
    bankBeanName: null,
    accountExists: undefined,
	serverAppData:{
		bankParams: {
			legalData: {
				accountExists: undefined
			}
		}
	},
    status: null,
	//fieldsMap: commonAcqFieldsMap,
	commissionsOptions: [],

    MCCPointsError: false,

	allTmsForOneApp: {
		data: [],
		loading: false,
	},
	cacheDataPoints: [],
    displayTerminalDataConf: false,
    terminalsTabNotSaved: false,
	guideData: {
		contactPerson: {},
		pretensionPerson: {
			isContactPerson: true
		},
		director: {},
		signer: {
			isDirector: false,
		},
		accountant: {
			isDirector: false,
		},
		foundationIndividuals:[],
		foundationLegals:[],
		beneficiaryIndividuals:[],
		governingBodies:[],
	},
	guideDataAsString: {
		contactPersonAsString: '',
		pretensionPersonAsString: '',
		directorAsString: '',
		signerAsString: '',
		accountantAsString: '',
		foundationIndividualsAsString: '',
		foundationLegalsAsString: '',
		beneficiaryIndividualsAsString: '',
		governingBodiesAsString: '',
	},
};

/**
 прослойка для получения актуального состояния
 */
export const updateApplicationGuideData = createAsyncThunk(
    'acqAppData/update-application-guideData',
    async ({saveApplication, params = {},}, thunkAPI) => {
        const guideData = thunkAPI.getState().acqAppData.guideData
		saveApplication({ guideData, params })
    }
);

const acqAppData = createSlice({
    name: "acqAppData",
    initialState,
    reducers: {

        setBankBeanName: (state, { payload }) => {
            state.bankBeanName = payload 
        }, 
        setServerAppData: (state, { payload }) => {
            state.serverAppData = payload 
        },        
		setIsAccountExists: (state, { payload }) => {
            state.serverAppData.bankParams.legalData.accountExists = payload 
        },  
        setFieldsMap: (state, { payload }) => {
            state.fieldsMap = payload 
        },  
        setMCCPointsError: (state, { payload }) => {
            state.MCCPointsError = payload 
        }, 
        setTerminalsLoading: (state, { payload }) => {
            state.allTmsForOneApp.loading = payload 
        },  
        setCacheDataPoints: (state, { payload }) => {
            state.cacheDataPoints = payload 
			state.allTmsForOneApp.data = payload
        },  
        setDisplayTerminalDataConf: (state, { payload }) => {
            state.displayTerminalDataConf = payload 
        },  
        setTerminalsTabNotSaved: (state, { payload }) => {
            state.terminalsTabNotSaved = payload 
        },  
        setAppLoading: (state, { payload }) => {
            state.loading = {...state.loading, ...payload} 
        }, 
        setAppStatus: (state, { payload }) => {
            state.status = payload
        }, 
        setCommissionsOptions: (state, { payload }) => {
            state.commissionsOptions = payload 
        }, 
        setGuideDataSectionByType: (state, { payload }) => {
			const { sectionType, ...restFields } = payload

			const getDataAsString = (data) => {
				return data?.firstName && `${data?.lastName || ''} ${data?.firstName || ''} ${data?.otherName || ''} ${(data?.phone && data?.phoneCode) ? `+${data?.phoneCode}${data?.phone}` : ''}`
			}

			const getListDataAsString = (data) => {
				if(!data?.length) return ''

				return data.reduce((prev, curr) => {
					const currentData = `${curr?.orgNameWithOutOpf || ''} ${curr?.inn ? ('ИНН: '+ curr?.inn) : ''} ${curr?.lastName || ''} ${curr?.firstName || ''} ${curr?.otherName || ''} ${(curr?.phone && curr?.phoneCode) ? `+${curr?.phoneCode}${curr?.phone}` : ''} ${curr?.email || ''}`
					const result = currentData.trim().length ? currentData : 'Просмотр'
					return [...prev, result]
				},[]).join(', ')
			}

			switch (sectionType) {
				case 'contactPerson':
					state.guideData.contactPerson = restFields 
					state.guideDataAsString.contactPersonAsString = getDataAsString(restFields)
					break;
				case 'pretensionPerson':
					state.guideData.pretensionPerson = restFields 
					if(restFields.isContactPerson && state.guideDataAsString.contactPersonAsString){
						state.guideDataAsString.pretensionPersonAsString = state.guideDataAsString.contactPersonAsString
					} else {
						state.guideDataAsString.pretensionPersonAsString = getDataAsString(restFields)
					}
					break;
				case 'director':
					
					state.guideData.director = restFields 
					if(restFields?.legalType === 'LEGAL') state.guideData.signer.isDirector = false
					if(restFields?.isContactPerson && state.guideDataAsString.contactPersonAsString){
						state.guideDataAsString.directorAsString = state.guideDataAsString.contactPersonAsString
					} else {
						state.guideDataAsString.directorAsString = getDataAsString(restFields)
					}
					
					break;
				case 'signer':
					state.guideData.signer = restFields
					if(restFields?.isDirector && state.guideDataAsString.directorAsString){
						state.guideDataAsString.signerAsString = state.guideDataAsString.directorAsString
					} else {
						const getSignerAsString = () => {
							if(restFields?.legalType === 'LEGAL'){
								const {inn, ogrn ,orgNameWithOutOpf} = restFields
								return `${orgNameWithOutOpf || ''} ${inn ? ('ИНН: '+ inn) : ''} ${ogrn ? ('ОГРН: '+ ogrn) : ''}`
							}
							return getDataAsString(restFields)
						};
						
						state.guideDataAsString.signerAsString = getSignerAsString()
					}
					break;
				case 'accountant':
					state.guideData.accountant = restFields
					if(restFields?.isDirector && state.guideDataAsString.directorAsString){
						state.guideDataAsString.accountantAsString = state.guideDataAsString.directorAsString
					} else {
						state.guideDataAsString.accountantAsString = getDataAsString(restFields)
					}
					break;
				case 'foundationLegals':

					state.guideData.foundationLegals = restFields?.[sectionType] || []
					state.guideDataAsString.foundationLegalsAsString = getListDataAsString(restFields?.foundationLegals)
					break;
				case 'foundationIndividuals':
					state.guideData.foundationIndividuals = restFields?.[sectionType] || []
					state.guideDataAsString.foundationIndividualsAsString = getListDataAsString(restFields?.foundationIndividuals)
					break;
				case 'beneficiaryIndividuals':
		
					state.guideData.beneficiaryIndividuals = restFields?.[sectionType] || []
					state.guideDataAsString.beneficiaryIndividualsAsString = getListDataAsString(restFields?.beneficiaryIndividuals)
					break;
				case 'governingBodies':
					const getGoverningBodiesAsString = (data) => {
						if(!data?.length) return ''
		
						return data.reduce((prev, curr) => {
							const governingBodiesLabel = governingBodiesPosition.find(el => el.value === curr?.name)?.label || ''
							const governingBodiesName = curr.nameOther ? curr.nameOther : governingBodiesLabel
							const positionList = curr.participants && curr.participants.length > 0
							? curr.participants.map(p => p.fio).join(', ')
							: '';
							const currentData = governingBodiesName + ' - ' + positionList
							return [...prev, currentData]
						},[]).join('; ')
					}

					state.guideData.governingBodies = restFields?.[sectionType] || []
					state.guideDataAsString.governingBodiesAsString = getGoverningBodiesAsString(restFields?.governingBodies)
					break;
				default:
					break;
			}
        }, 
        clearData: () => initialState,
    },
})

export const {  
	setBankBeanName, 
	clearData, 
	setMCCPointsError,
	setCommissionsOptions,
	setDisplayTerminalDataConf,
	setTerminalsTabNotSaved,
	setCacheDataPoints,
	setTerminalsLoading,
	setAppLoading,
	setGuideDataSectionByType,
	setFieldsMap,
	setAppStatus,
	setIsAccountExists,
	setServerAppData,
 } = acqAppData.actions
export default acqAppData.reducer
