import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customersAPI from '../../api/v2/customersAPI';
import posesAPI from '../../api/v2/posesAPI';
import merchantAPI from '../../api/merchant/merchant_points'
import { getAddressWithRegionLabel } from '../shares/helpers/generateAddressLine';

export const fetchChangePos = createAsyncThunk(
    "pos-change/change/fetch",
    async ({ posId, payload }, thunkAPI) => {
        const { installationAddress, contactPerson } = thunkAPI.getState().posChange
        const requestData = { ...payload, installationAddress, contactPerson }
        try {
            const response = await posesAPI.updateOne({ posId, payload: requestData })

            if (!response.success) {
                return thunkAPI.rejectWithValue(response);
            }

            return response; 
        } catch (apiError) {
            return apiError
        }
    }
)

export const fetchGetPosRecord = createAsyncThunk(
    "pos-change/pos-record/fetch",
    async (posId) => {
        let response = await posesAPI.getOneForUpdate(posId)
        return response
    }
)

export const getCustomersOptions = createAsyncThunk(
    "pos-change/customers",
    async () => {
        const response = await customersAPI.getData()
        return response
    }
)

export const getActivitiesKinds = createAsyncThunk(
    "pos-change/activityKinds",
    async () => {
        const response = await merchantAPI.getActivitiesKinds()
        const options = Object.entries(response).map(item => ({value: +item[0], label: item[1]}))
        return options
    }
)

export const getCurrentCustomerData = createAsyncThunk(
    "pos-create/customer-fetch",
    async (id) => {
        const response = await customersAPI.getOneForRecord(id)
        return response
    }
)

const initialState = {
    withNextStep: false,
    installationAddress: {},
    contactPerson: {},
    currentCustomerData: {},
    currentCustomer: null,
    currentCustomerAddress: null,
    posRecord: {
        record: {},
        loading: false,
    },
    options: {
        customers: [],
        activitiesKinds: []
    }
}

const posCreateSlice = createSlice({
    name: "pos-change",
    initialState,
    reducers: {
        addPosAddress: (state, { payload }) => {
            state.installationAddress = payload || initialState.installationAddress
        },
        addPosContactPerson: (state, { payload }) => {
            state.contactPerson = payload || initialState.contactPerson
        },
        clearFormData: (state) => {
            state.withNextStep = initialState.withNextStep
            state.installationAddress = initialState.installationAddress
            state.contactPerson = initialState.contactPerson
            state.currentCustomer = initialState.currentCustomer
            state.currentCustomerAddress = initialState.currentCustomerAddress
            state.options = initialState.options
        }
    },
    extraReducers: {
        [fetchChangePos.pending]: (state) => {
            state.loading = true
        },
        [fetchChangePos.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.withNextStep = false
        },
        [fetchChangePos.rejected]: (state) => {
            state.loading = false
            state.withNextStep = false
        },
        [fetchGetPosRecord.pending]: (state) => {
            state.posRecord.loading = true
        },
        [fetchGetPosRecord.fulfilled]: (state, { payload }) => {
			

            state.contactPerson = payload.contactPerson || {}
            state.installationAddress = getAddressWithRegionLabel(payload.installationAddress) || {}
            state.posRecord.record = payload || {}
            state.posRecord.loading = false
            state.withNextStep = false
        },
        [fetchGetPosRecord.rejected]: (state) => {
            state.posRecord.loading = false
            state.withNextStep = false
        },
        [getCustomersOptions.fulfilled]: (state, { payload }) => {
            state.customers = payload.clients
            state.options.customers = payload.clients.map(customer => ({ key: customer.id, value: customer.id, label: `${customer.businessName} (${customer.TIN})` }))
        },
        [getCurrentCustomerData.fulfilled]: (state, { payload }) => {
            state.currentCustomerData = payload
        },
        [getActivitiesKinds.fulfilled]: (state, { payload }) => {
            state.options.activitiesKinds = payload
        },
    }
});

export const {
    addPosAddress,
    addPosContactPerson,
    clearFormData
} = posCreateSlice.actions
export default posCreateSlice.reducer