import { useSelector } from "react-redux";
import { selectDictBanks, selectPermissions, selectUserTokenBankBean } from "../shares/helpers/commonReduxSelectors";

export const checkPermissionBySection = (permissions, type) => {
	if(type === sbpPermissionSectionType.customer){
		return (
			permissions["clientsSection:all"] 
			|| permissions["clientsSection:update"] 
			|| permissions["clientsSection:merchants:crud"]
			|| permissions["clientsSection:points:crud"]
			|| permissions["clientsSection:points:fastPaymentSetup"]
		)
	}
	if(type === sbpPermissionSectionType.customerCrud){
		return (
			permissions["clientsSection:all"] 
			|| permissions["clientsSection:update"] 
			|| permissions["clientsSection:merchants:crud"]
			|| permissions["clientsSection:merchants:sbp"]
		)
	}
	if(type === sbpPermissionSectionType.pos){
		return (
			permissions["clientsSection:all"] 
			|| permissions["clientsSection:update"]
			|| permissions["clientsSection:points:crud"] 
			|| permissions["clientsSection:points:fastPaymentSetup"] 
		)
	}
	
};

export const checkUpdatePermissionBySection = (permissions, type) => {
	if(type === sbpPermissionSectionType.customer){
		return (
			permissions["clientsSection:update"] 
			|| permissions["clientsSection:merchants:crud"]
			|| permissions["clientsSection:points:crud"]
			|| permissions["clientsSection:points:fastPaymentSetup"]
		)
	}
	if(type === sbpPermissionSectionType.customerCrud){
		return (
			permissions["clientsSection:update"] 
			|| permissions["clientsSection:merchants:crud"]
			|| permissions["clientsSection:merchants:sbp"]
		)
	}
	if(type === sbpPermissionSectionType.pos){
		return (
			permissions["clientsSection:update"]
			|| permissions["clientsSection:points:crud"] 
			|| permissions["clientsSection:points:fastPaymentSetup"] 
		)
	}
};

export const sbpPermissionSectionType = {
	pos: 'pos',
	customer:'customer',
	customerCrud:'customer-create-update',
};

export const useCheckSbpPermission = ({fastPaymentBankId,activateFastPayments, type}) => {
	const bankList = useSelector(selectDictBanks)
	const permissions = useSelector(selectPermissions);
	const tokenBankBean = useSelector(selectUserTokenBankBean);

	if(!bankList.length) return false
	const isVppSbpAgent	= bankList.find(b => b.id === fastPaymentBankId)?.vppSbpAgent
	return { 
		isDisplayed: !['KONTUR', 'MKB'].includes(tokenBankBean) && checkPermissionBySection(permissions, type) && activateFastPayments && isVppSbpAgent,
		editAllow: checkUpdatePermissionBySection(permissions, type),
	}
};

export const useCheckQrMid = ({fastPaymentBankId,activateFastPayments, type}) => {
	const bankList = useSelector(selectDictBanks)
	const permissions = useSelector(selectPermissions);
	const tokenBankBean = useSelector(selectUserTokenBankBean);

	if(!bankList.length) return false
	const isSbp	= bankList.find(b => b.id === fastPaymentBankId)?.sbp
	return { 
		isDisplayed: ['KONTUR', 'MKB'].includes(tokenBankBean) && checkPermissionBySection(permissions, type) && activateFastPayments && isSbp,
	}
};
